import { createActions, handleActions } from 'redux-actions';

export interface GlobalState {
  currentPageTitle: string;
  sidebarMobileOpen: boolean;
  sidebarOpen: boolean;
  username: string;
  roles: string[];
}

const initialState: GlobalState = {
  currentPageTitle: '',
  sidebarMobileOpen: false,
  sidebarOpen: true,
  username: null,
  roles: [],
};

export const actions = createActions({
  SET_CURRENT_PAGE_TITLE: (title: string) => title,
  SET_USER: (username: string, controllerRoles: string[], sunshadeRoles: string[]) => ({
    username,
    controllerRoles,
    sunshadeRoles,
  }),
  TOGGLE_SIDEBAR: () => null,
  TOGGLE_SIDEBAR_MOBILE: () => null,
});

export const reducer = handleActions<GlobalState>(
  {
    [actions.setCurrentPageTitle.toString()]: (state: GlobalState, action: any) => ({ ...state, currentPageTitle: action.payload }),
    [actions.setUser.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      username: action.payload.username,
      roles: [
        ...action.payload.controllerRoles,
        ...action.payload.sunshadeRoles,
      ]
    }),
    [actions.toggleSidebar.toString()]: (state: GlobalState) => ({ ...state, sidebarOpen: !state.sidebarOpen }),
    [actions.toggleSidebarMobile.toString()]: (state: GlobalState) => ({ ...state, sidebarMobileOpen: !state.sidebarMobileOpen }),
  },
  initialState
);
