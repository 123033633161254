import { MenuItem } from '../../models/dtos/menuItem';

const menu: MenuItem[] = [
  {
    label: 'Home',
    to: '/home',
  },
  {
    allow: roles => roles.includes('CALIMA_STATS') || roles.includes('CALIMA_FEEDBACK'),
    label: 'Calima',
    content: [
      {
        allow: roles => roles.includes('CALIMA_STATS'),
        label: 'Estatisticas',
        to: '/calima/stats',
      },
      {
        allow: roles => roles.includes('CALIMA_FEEDBACK'),
        label: 'Feedback',
        to: '/calima/feedback',
      },
      {
        allow: roles => roles.includes('CALIMA_NPS'),
        label: 'Pesquisa NPS',
        to: '/calima/nps',
      },
    ]
  },
  {
    allow: roles => roles.includes('LOG_CLOUDS'),
    label: 'Log Clientes',
    to: '/logElastic',
  },
  {
    allow: roles => roles.includes('MANAGE_APPS') || roles.includes('MANAGE_APP_VERSIONS'),
    label: 'Controle de versão',
    to: '/versionManager/apps',
  },
  {
    allow: roles => roles.includes('MANAGE_ROLES') || roles.includes('MANAGE_USERS'),
    label: 'Controle de usuário',
    content: [
      {
        allow: roles => roles.includes('MANAGE_ROLES'),
        label: 'Permissões',
        to: '/auth/roles',
      },
      {
        allow: roles => roles.includes('MANAGE_USERS'),
        label: 'Usuários',
        to: '/auth/users',
      },
    ]
  },
];

export default menu;
