const environmentMap = {
  stable: {
    AUTH: 'https://api.calima.app/auth/',
    TELEMETRY: 'https://api.calima.app/telemetry/',
    DASHBOARD: 'https://api.calima.app/dashboard/',
    WAREHOUSE: 'https://api.calima.app/warehouse/',
  },
  test: {
    AUTH: 'https://test-api.calima.app/auth/',
    TELEMETRY: 'https://test-api.calima.app/telemetry/',
    DASHBOARD: 'https://test-api.calima.app/dashboard/',
    WAREHOUSE: 'https://test-api.calima.app/warehouse/',
  },
  beta: {
    AUTH: 'https://beta-api.calima.app/auth/',
    TELEMETRY: 'https://beta-api.calima.app/telemetry/',
    DASHBOARD: 'https://beta-api.calima.app/dashboard/',
    WAREHOUSE: 'https://beta-api.calima.app/warehouse/',
  },
};

export const Environment = environmentMap[process.env.REACT_APP_ENV || 'stable'];
