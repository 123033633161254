import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AlertContainer, Dock, Progress } from 'summer';
import Routes from './Routes';
import store from './store';

import './App.css';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer position="top-right" newestOnTop={true} />
        <AlertContainer />
        <Progress />
        <Routes />
        <Dock />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
