import React, { FC, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import menu from '../../pages/menu';
import SidebarMenuItem from './SidebarMenuItem';

const SidebarMenu: FC = () => {
  const globalReducer = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const [lastItemClicked, setLastItemClicked] = useState('');

  const toggleItem = event => {
    event.preventDefault();
    const innerText = event.currentTarget.innerText.replace(/\n/g, '');
    setLastItemClicked(lastItemClicked === innerText ? '' : innerText);
  };

  const filterAllowed = item => !item.allow || item.allow(globalReducer.roles);

  return (
    <PerfectScrollbar>
      <div className="sidebar-navigation">
        <ul>
          {menu.filter(filterAllowed).map((item, index) => (
            <SidebarMenuItem
              key={index}
              item={item}
              onClick={toggleItem}
              isOpen={item.label === lastItemClicked}
              filterAllowed={filterAllowed}
            />
          ))}
        </ul>
      </div>
    </PerfectScrollbar>
  );
};

export default SidebarMenu;
