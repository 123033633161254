import { createLoadable } from 'summer';
import { PageItem } from '../../models/dtos/pageItem';

const pages: PageItem[] = [
  {
    component: createLoadable(() => import('./home/Home'), 'Home'),
    exact: true,
    path: '/home',
  },
  {
    allow: roles => roles.includes('CALIMA_STATS'),
    component: createLoadable(() => import('./calima/stats/Stats'), 'CalimaStats'),
    exact: true,
    path: '/calima/stats',
  },
  {
    allow: roles => roles.includes('CALIMA_FEEDBACK'),
    component: createLoadable(() => import('./calima/feedback/Feedback'), 'CalimaFeedback'),
    exact: true,
    path: '/calima/feedback',
  },
  {
    allow: roles => roles.includes('LOG_CLOUDS'),
    component: createLoadable(() => import('./logElastic/LogElastic'), 'LogElastic'),
    exact: true,
    path: '/logElastic',
  },
  {
    allow: roles => roles.includes('MANAGE_ROLES'),
    component: createLoadable(() => import('./auth/roles/RolesSearchView'), 'RolesSearchView'),
    exact: true,
    path: '/auth/roles',
  },
  {
    allow: roles => roles.includes('MANAGE_ROLES'),
    component: createLoadable(() => import('./auth/roles/RolesEditView'), 'RolesEditView'),
    exact: true,
    path: '/auth/roles/edit/:roleId?',
  },
  {
    allow: roles => roles.includes('MANAGE_USERS'),
    component: createLoadable(() => import('./auth/users/UserSearchView'), 'UserSearchView'),
    exact: true,
    path: '/auth/users',
  },
  {
    allow: roles => roles.includes('MANAGE_APPS') || roles.includes('MANAGE_APP_VERSIONS'),
    component: createLoadable(() => import('./versionManager/apps/AppsSearchView'), 'AppsSearchView'),
    exact: true,
    path: '/versionManager/apps',
  },
  {
    allow: roles => roles.includes('MANAGE_APPS'),
    component: createLoadable(() => import('./versionManager/apps/AppsEditView'), 'AppsEditView'),
    exact: true,
    path: '/versionManager/apps/edit/:appId?',
  },
  {
    allow: roles => roles.includes('CALIMA_NPS'),
    component: createLoadable(() => import('./calima/nps/CampanhaNPSSearchView'), 'CampanhaNPSSearchView'),
    exact: true,
    path: '/calima/nps',
  },
  {
    allow: roles => roles.includes('CALIMA_NPS_CAMPANHA'),
    component: createLoadable(() => import('./calima/nps/CampanhaNPSEditView'), 'CampanhaNPSEditView'),
    exact: true,
    path: '/calima/nps/edit/:campanhaId?',
  },
  {
    allow: roles => roles.includes('CALIMA_NPS'),
    component: createLoadable(() => import('./calima/nps/CampanhaNPSResultadoView'), 'CampanhaNPSResultadoView'),
    exact: true,
    path: '/calima/nps/resultado/:campanhaId?',
  },
];

export default pages;
