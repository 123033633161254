import { combineReducers } from 'redux';
import { dockReducer, DockState, progressReducer, ProgressState, UserState, userReducer, ProState, proReducer } from 'summer';
import { GlobalState, reducer as globalReducer } from './global.duck';

export { actions as globalActions } from './global.duck';

export interface Reducers {
  dockReducer: DockState;
  globalReducer: GlobalState;
  proReducer: ProState,
  progressReducer: ProgressState;
  userReducer: UserState,
}

export const reducers = combineReducers({
  dockReducer,
  globalReducer,
  proReducer,
  progressReducer,
  userReducer,
});

