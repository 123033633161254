import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { useLocalStorage } from 'summer';
import { LOCAL_STORAGE_LOGIN_TOKEN } from '../../../utilities/constants';

import './Header.css';

const Header: FC = () => {
  const { currentPageTitle, username } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const [, , deleteToken] = useLocalStorage(LOCAL_STORAGE_LOGIN_TOKEN);

  const onClick = () => null;

  const logout = () => {
    deleteToken();
    window.location.href = '/';
  };

  return (
    <div className="app-header app-header--shadow app-header--opacity-bg">
      <div className="app-header--pane" style={{ width: 296 }}>
        <button onClick={onClick} className="navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn">
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <h4 className="pt-2 pl-1">{currentPageTitle}</h4>
      </div>
      <div className="app-header--pane">
        <span className="mr-2">{username}</span>
        <Button onClick={logout} color="link" id="btnLogoutTooltip" className="px-0">
          <FontAwesomeIcon icon="sign-out-alt" size="lg" />
        </Button>
        <UncontrolledTooltip target="btnLogoutTooltip" placement="bottom">
          Sair do Sistema
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

export default Header;
