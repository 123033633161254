import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { dockActions, ERROR, showToast, SuspenseLoading, useLocalStorage, proActions } from 'summer';
import { globalActions, Reducers } from './store/ducks';
import { GlobalState } from './store/ducks/global.duck';
import { LOCAL_STORAGE_LOGIN_TOKEN } from './utilities/constants';
import Sidebar from './views/components/sidebar/Sidebar';
import LoginPage from './views/pages/login/Login';
import pages from './views/pages/routes';
import { validateToken } from './services/auth.service';

const pageVariants = {
  in: {
    opacity: 1,
    scale: 1,
  },
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  out: {
    opacity: 0,
    scale: 1.01,
  },
};

const pageTransition = {
  duration: 0.4,
  ease: 'anticipate',
  type: 'tween',
};

const Routes: FC = () => {
  const globalReducer = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const [loginToken] = useLocalStorage(LOCAL_STORAGE_LOGIN_TOKEN);
  const dispatch = useDispatch();
  const history = useHistory();

  const [, , deleteToken] = useLocalStorage(LOCAL_STORAGE_LOGIN_TOKEN);

  useEffect(
    () => {
      if (loginToken && !globalReducer.username) {
        validateToken({
          errorFunction: mensagem => showToast(mensagem, ERROR),
          thenFunction: res => {
            if (res.funcionario) {
              dispatch(globalActions.setUser(res.username, res.controllerRoles, res.sunshadeRoles));
              dispatch(proActions.setIsPro(true));
            } else {
              deleteToken();
              window.location.href = '/';
            }
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    dispatch(dockActions.destroyDock());
  }, [dispatch, history.location]);

  const loginOrWait = () => (loginToken && !globalReducer.username ? <SuspenseLoading /> : <Redirect to="/" />);

  const getContent = () => {
    return (
      <Sidebar>
        <Switch>
          {pages.filter(it => it.allow ? it.allow(globalReducer.roles) : true).map(({ component, exact, ...routeProps }, i) => (
            <Route key={i} component={component} exact={exact} {...routeProps} />
          ))}
        </Switch>
      </Sidebar>
    );
  };

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Route exact={true} path="/">
            {globalReducer.username && <Redirect to="/home" />}
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
              {loginToken ? <SuspenseLoading /> : <Route exact={true} path="/" component={LoginPage} />}
            </motion.div>
          </Route>
          <Route>{globalReducer.username ? getContent() : loginOrWait()}</Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
