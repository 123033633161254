import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import logo from '../../../assets/sunshade.png';
import { globalActions } from '../../../store/ducks';

const SidebarHeader: FC = () => {
  const dispatch = useDispatch();

  const toggle = () => dispatch(globalActions.toggleSidebar());
  const toggleMobile = () => dispatch(globalActions.toggleSidebarMobile());

  return (
    <div className="app-sidebar--header">
      <div className="app-sidebar-logo">
        <Link to="#" title="Sunshade Dashboard" className="app-sidebar-logo">
          <img alt="Sunshade Dashboard" src={logo} />
        </Link>
      </div>
      <button className="navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn" onClick={toggleMobile}>
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <button className="expand-sidebar-btn btn btn-sm" id="ExpandSidebarTooltip" onClick={toggle}>
        <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
      </button>
      <UncontrolledTooltip target="ExpandSidebarTooltip" container=".app-sidebar" placement="right">
        Exibir menu
      </UncontrolledTooltip>
    </div>
  );
};

export default SidebarHeader;
