import { AxiosInstance } from 'axios';
import { AxiosParam, send } from 'summer';
import { Environment } from '../environment';
import { create } from '../utilities/axios.util';
import { CustomRole } from '../models/dtos/customRole';
import { PersistUserRoles } from '../models/dtos/persistUserRoles';

let instance = null;

function getService(): AxiosInstance {
  if (!instance) {
    instance = create(Environment.AUTH);
  }
  return instance;
}

export function login(username: string, password: string, param: AxiosParam<any>) {
  return send(
    getService().post<any>('/login', { type: 'controller', username, password }),
    param
  );
}

export function validateToken(param: AxiosParam<any>) {
  return send(getService().get<any>('/validateToken'), param);
}

export function findAllRoles(page: number, limit: number, param: AxiosParam<any>) {
  return send(getService().get(`/role?page=${page}&limit=${limit}&sort=name:DESC`), param);
}

export function findRoleById(roleId: string, param: AxiosParam<CustomRole>) {
  return send(getService().get(`/role/${roleId}`), param);
}

export function persistRole(role: CustomRole, param: AxiosParam<CustomRole>) {
  return send(getService().post<CustomRole>('/role', role), param);
}

export function deleteRole(roleId: string, param: AxiosParam<void>) {
  return send(getService().delete(`/role/${roleId}`), param);
}

export function findAllISGS(search: string, page: number, limit: number, param: AxiosParam<any>) {
  return send(getService().get(`/isgs?search=${search}&page=${page}&limit=${limit}`), param);
}

export function findAllUserRoles(username: string, param: AxiosParam<any>) {
  return send(getService().get(`/userRole?user=${username}`), param);
}

export function findAllRolesSelect(param: AxiosParam<any>) {
  return send(getService().get(`/userRole/select`), param);
}

export function persistUserRoles(body: PersistUserRoles, param: AxiosParam<any>) {
  return send(getService().post('/userRole', body), param);
}
