import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Form, Row } from 'reactstrap';
import { ERROR, InputGroupInline, InputType, showToast, useLocalStorage, proActions } from 'summer';
import useLoading from '../../../hooks/useLoading';
import { login } from '../../../services/auth.service';
import { globalActions } from '../../../store/ducks';
import { LOCAL_STORAGE_LOGIN_TOKEN } from '../../../utilities/constants';
import Networks from '../../components/networks/Networks';
import logo from '../../../assets/sunshade.png';
import background from '../../../assets/background.jpeg';

import './Login.css';

interface LoginRouter {
  usuario: string;
  usuarioRecuperar?: string;
  senha: string;
}

const initialState: LoginRouter = {
  senha: '',
  usuario: '',
};

const Login: FC = () => {
  const [, writeLoginTokenStorage] = useLocalStorage(LOCAL_STORAGE_LOGIN_TOKEN);

  const formProps = useForm<LoginRouter>({ defaultValues: initialState, mode: 'onBlur' });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const loading = useLoading();
  const history = useHistory();
  const dispatch = useDispatch();

  const errorFunc = mensagem => {
    loading.hide();
    showToast(mensagem, ERROR);
    setIsSubmitting(false);
  };

  const efetuarLogin = (data: LoginRouter) => {
    login(data.usuario, data.senha, {
      errorFunction: errorFunc,
      thenFunction: res => {
        if (res.funcionario) {
          writeLoginTokenStorage(res.token);
          dispatch(globalActions.setUser(res.username, res.controllerRoles, res.sunshadeRoles));
          dispatch(proActions.setIsPro(true));
          history.push('/home');
        } else {
          errorFunc('Usuário não possui permissão para acesso ao dashboard.');
        }
      },
    });
  };

  const onSubmit = async (data: LoginRouter, event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    efetuarLogin(data);
  };

  return (
    <div className="login-wrapper">
      <div className="login-container min-vh-100 d-flex justify-content-center">
        <Row>
          <Col>
            <div className="text-center">
              <a href="https://www.projetusti.com.br/" target="_blank" rel="noopener noreferrer" className="mb-2">
                <img style={{ width: 200 }} src={logo} alt="Sunshade Dashboard" />
              </a>
              <div className="titles">
                <h2>Bem-vindo!</h2>
                <h3>Preencha seus dados para entrar</h3>
              </div>
            </div>
            <Form noValidate={true} onSubmit={formProps.handleSubmit(onSubmit)}>
              <InputGroupInline formProps={{ ...formProps, validation: { required: true } }} id="usuario" label="Informe seu usuário" />
              <InputGroupInline formProps={{ ...formProps, validation: { required: true } }} id="senha" label="Informe sua senha" type={InputType.PASSWORD} />
              <Row className="pt-2">
                <Col>
                  <Button size="sm" block={true} color="first" disabled={isSubmitting} className="mb-2">
                    Entrar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Networks className="networks-login" />
        <a href="https://www.projetusti.com.br/" target="_blank" rel="noopener noreferrer" className="copyright">
          &copy; ProjetusTI
        </a>
      </div>
      <div className="image-container" style={{ backgroundImage: `url('${background}')` }} />
    </div>
  );
};

export default Login;
