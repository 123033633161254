import axios, { AxiosResponse } from 'axios';
import { LOCAL_STORAGE_LOGIN_TOKEN } from './constants';

export const create = server => {
  const instance = axios.create({ baseURL: server });

  instance.interceptors.request.use(
    value => {
      const token = window.localStorage.getItem(LOCAL_STORAGE_LOGIN_TOKEN);
      if (token) {
        value.headers.Authorization = `Bearer ${token}`;
      }
      return value;
    },
    err => Promise.reject(err)
  );

  instance.interceptors.response.use(
    conf => conf,
    err => {
      if (err.response.status === 401) {
        window.localStorage.removeItem(LOCAL_STORAGE_LOGIN_TOKEN);
        window.location.reload();
      }
      return Promise.reject(err);
    }
  );

  return instance;
};

const removeSlashes = item => {
  let result = item;

  if (result[0] === '/') {
    result = item.substr(1);
  }

  if (result[result.length - 1] === '/') {
    result = item.slice(0, -1);
  }

  return result;
};

export const buildUrl = (...items) => items.map(removeSlashes).join('/');
