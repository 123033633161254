import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { MenuItem } from '../../../models/dtos/menuItem';

export interface SidebarMenuItemProps {
  item: MenuItem;
  isOpen: boolean;
  onClick: (event) => void;
  filterAllowed: (item) => boolean;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({ item, isOpen, onClick, filterAllowed }) => {
  const [itemOpen, setItemOpen] = useState(false);
  const [lastItemClicked, setLastItemClicked] = useState('');

  useEffect(() => {
    setItemOpen(isOpen);
  }, [isOpen]);

  const toggleItem = event => {
    event.preventDefault();
    const innerText = event.currentTarget.innerText.replace(/\n/g, '');
    setLastItemClicked(lastItemClicked === innerText ? '' : innerText);
  };

  const getItemIcon = () => (item.icon ? <span className={clsx('sidebar-icon', item.icon)} /> : null);

  const navDropdown = () => {
    return (
      <li>
        <a href="/" onClick={onClick} className={clsx({ active: itemOpen })}>
          {getItemIcon()}
          <span className="sidebar-item-label">{item.label}</span>
          <span className="sidebar-icon-indicator">
            <ChevronRight />
          </span>
        </a>
        <Collapse isOpen={itemOpen}>
          <ul>
            {item.content.filter(filterAllowed).map((it, index) => (
              <SidebarMenuItem
                key={index}
                item={it}
                onClick={toggleItem}
                isOpen={it.label === lastItemClicked}
                filterAllowed={filterAllowed}
              />
            ))}
          </ul>
        </Collapse>
      </li>
    );
  };

  const navLink = () => {
    const url = item.to || '';
    const itemIcon = getItemIcon();

    return (
      <li>
        {isExternal(url) ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {itemIcon}
            {item.label}
          </a>
        ) : (
          <NavLink to={url}>
            {itemIcon}
            {item.label}
          </NavLink>
        )}
      </li>
    );
  };

  const isExternal = (url: string) => {
    const link = url ? url.substring(0, 4) : '';
    return link === 'http';
  };

  return item.content ? navDropdown() : navLink();
};

export default SidebarMenuItem;
